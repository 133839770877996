import type { ReleaseResource, ResourceCollection, EventCategoryResource, EventResource } from "@octopusdeploy/octopus-server-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import * as React from "react";
import { repository } from "~/clientInstance";
import type { Errors } from "~/components/DataBaseComponent";
import DataLoader from "~/components/DataLoader";
import EventListing, { NotPermittedToViewEventsCallout } from "~/components/EventListing";
import { useEnabledFeatureToggle } from "~/components/FeatureToggle/New/FeatureToggleContext";
import PaperLayout from "~/components/PaperLayout";
import { isAllowed } from "~/components/PermissionCheck/PermissionCheck";

interface EventListLoaderProps {
    release: ReleaseResource;
}

const EventListingLoader = DataLoader<{ events: ResourceCollection<EventResource>; eventCategories: EventCategoryResource[] }>();

export const ListEventsForRelease: React.FC<EventListLoaderProps> = (props) => {
    const permitEvents: boolean = isAllowed({ permission: Permission.EventView, project: props.release.ProjectId, wildcard: true });
    const useOptimization = useEnabledFeatureToggle("PermissionCheckOptimizationFeatureToggle");

    const loadEventsAndCategories = React.useCallback(async () => {
        const events = useOptimization ? repository.Events.getUnpaginatedEvents({ regarding: [props.release.Id] }) : repository.Events.list({ regarding: [props.release.Id] });
        const eventCategories = repository.Events.categories({});

        return {
            events: await events,
            eventCategories: await eventCategories,
        };
    }, [props.release, useOptimization]);

    const displayHistoryTable = React.useCallback(
        ({ events, eventCategories }: { events: ResourceCollection<EventResource>; eventCategories: EventCategoryResource[] }) => <EventListing data={events} regarding={[props.release.Id]} eventCategories={eventCategories} />,
        [props.release]
    );

    const handleNoDataMessage = React.useCallback(({ busy, errors }: { busy: boolean; errors: Errors | undefined }) => <PaperLayout errors={errors} busy={busy} />, []);

    return permitEvents ? (
        <EventListingLoader
            operationName="LoadEventsForRelease"
            load={loadEventsAndCategories}
            renderWhenLoaded={displayHistoryTable}
            renderAlternate={handleNoDataMessage}
            reloadOnPropChanges={true} // Reload the history data as a release is updated
        />
    ) : (
        <NotPermittedToViewEventsCallout />
    );
};
